































































































import { required } from "@/helpers/rules";
import { Expertise } from "@/models/entities/expertise.interface";
import { Role } from "@/models/entities/role.interface";
import { UserType } from "@/models/entities/user-type.interface";
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import { utils } from "@/services/api/utils.service";
import { Employee } from "@/models/entities/employee.interface";
import { getModule } from "vuex-module-decorators";
import store from "@/store";
import OverlayModule from "@/store/modules/overlay.module";
const overlayModule = getModule(OverlayModule, store);

const UserPermissions = () =>
  import(/* webpackChunkName: "UserPermissions" */ "./UserPermissions.vue");

@Component({
  components: {
    "user-permissions": UserPermissions,
  },
})
export default class UserRoleCard extends Vue {
  @Prop({ required: true, type: Boolean })
  private isNewUser: boolean;
  @Prop({ required: true, default: () => [], type: Array })
  private userTypes: UserType[];
  @Prop({ required: true, default: () => [], type: Array })
  private auditors: string[];
  @Prop({ required: true, default: () => [], type: Array })
  private expertise: Expertise[];
  @Prop({ required: true, default: null, type: Object })
  private roles: { [id: string]: Role[] };
  @PropSync("userTypeId", {
    required: false,
    default: null,
    type: String,
  })
  private _userTypeId: string;
  @PropSync("auditor", {
    required: false,
    default: null,
    type: String,
  })
  private _auditor: string;
  @PropSync("roleIds", {
    required: true,
    default: () => [],
    type: Array,
  })
  private _roleIds: string[];
  @PropSync("employeeId", {
    required: false,
    default: null,
    type: Number,
  })
  private _employeeId: number;
  @PropSync("expertiseId", {
    required: false,
    default: null,
    type: Number,
  })
  private _expertiseId: number;

  private required = required;
  private employees = [] as Omit<Employee, "employeeClassificationName">[];
  private lastUserTypeIdSelected = null;

  private created(): void {
    this._expertiseId && this.loadEmployees(this._expertiseId);
  }

  private onChangeUserType(userTypeId: string): void {
    this.emitUserTypeChange(userTypeId);
    this._userTypeId = userTypeId;
    this._expertiseId = null;
    this._employeeId = null;
    this.lastUserTypeIdSelected = userTypeId;
  }

  private emitUserTypeChange(userTypeId: string): void {
    this.$emit("change:userTypeId", userTypeId);
    if (this.lastUserTypeIdSelected === "OP" || userTypeId === "OP")
      this.$emit("change:op", userTypeId);
  }

  private onChangeExpertise(expertiseId: number) {
    this._expertiseId = expertiseId;
    expertiseId != null
      ? (this.loadEmployees(expertiseId),
        this.$emit("change:expertise", expertiseId))
      : (this._employeeId = null);
  }

  private async loadEmployees(expertiseId: number): Promise<void> {
    overlayModule.showOverlay();
    let e = await utils.getEmployeesByExpertiseId(expertiseId);
    this.employees = e.data;
  }
}
